import $ from 'jquery'
import '../sass/main.scss';
import menu from './menu';
import lazyLoad from './lazyLoad';
import smoothState from './smoothState';
import common from './common';
import faq from './faq';
import scroller from './vantaggi-scroll'

window.$ = require('jquery');
window.jQuery = require('jquery');

const siteGlobal = {
  initReady() {
    smoothState.initReady();
    menu.initReady();
    common.initReady();
    faq.initReady();
    scroller.initReady();
  },
  initLoad() {
  	lazyLoad.initLoad();
  },
  rebuildAllEvents() {
    menu.initReady();
    common.initReady();
    faq.initReady();
    lazyLoad.initLoad();
    scroller.initReady();
  },
  initScroll( ){
    menu.initScroll();
  },
  initResize() {
  },
  rebuild() {
    siteGlobal.rebuildAllEvents();
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on('rebuild', () => {
  siteGlobal.rebuild();
});

$(window).on('load', () => {
  $('#preloader').addClass('loaded');
  siteGlobal.initLoad();
});

$(window).on('resize', () => {
  siteGlobal.initResize();
});

$(window).on('scroll', () => {
  siteGlobal.initScroll();
});
