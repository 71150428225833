const faq = {
	initReady() {
		this.initFaq();
	},

	initFaq() {
		const $el = $('.faq')
		if($el.length == 0) return false

		$('.faq__head').on('click', function(){
			if($(this).hasClass('active')){
				$(this).parent().find('.faq__body').slideUp(400)
			} else {
				$(this).parent().find('.faq__body').slideDown(400)
			}
			$(this).toggleClass('active')
		})
	}
}

module.exports = faq;
