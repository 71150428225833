const menu = {
	initReady() {
		this.menuInit();
		this.menuSticky();
	},

	initScroll() {
		this.menuSticky();
	},

	menuInit() {
		$('.header__hamburger').on('click',() => {
			if($('.menu__mobile').is(':visible')){
				$('.header__hamburger, .header').removeClass('opened');
				$('.menu__mobile').fadeOut(350)
				$('.side-contact').css('z-index', 1010)
			} else {
				$('.header__hamburger, .header').addClass('opened');
				$('.menu__mobile').fadeIn(350)
				$('.side-contact').css('z-index', 900)
			}
		})

		$('.header__lang').on('click', function(){
			if($(this).find('ul').is(':visible')){
				$(this).removeClass('active');
				$(this).find('ul').slideUp(350)
			} else {
				$(this).addClass('active');
				$(this).find('ul').slideDown(350)
			}
		})
	},

	menuSticky() {
		const scroll = $(window).scrollTop();

		if($('.home-content').length > 0){
			if(scroll > 150) {
				$('.header').addClass('sticky')
			} else {
				$('.header').removeClass('sticky')
			}
		}else{
			$('.header').addClass('sticky')
		}
	}
}

module.exports = menu;
