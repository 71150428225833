 import 'slick-carousel'

 const scroller = {

  initReady(){

    $('.vantaggi__slider').slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1192,
          settings: {
            arrows: false
          }
        },
        {
          breakpoint: 769,
          settings: {
            arrows: false,
            adaptiveHeight: true
          }
        }
      ]
    });

  }

 }

 export default scroller;
