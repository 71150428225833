import "intersection-observer";
import scrollama from "scrollama";

const common = {
  scroller: scrollama(),

  initReady() {
		this.bindScrollingLink();
    this.initEnterViewport();
	},
  goToPos: (ref, offset = 0) => {
    const $ref = (typeof ref === 'string') ? $(ref) : ref;

    if ($(ref).length === 0) return false;
    const myPos = $ref.offset().top - offset;

    $('html,body').animate({
      scrollTop: myPos,
    });
  },

  bindScrollingLink() {
    $(document)
      .on('click', '.goToLink', function () {
        const target = $(this).data('target');
        const offset = $(this).data('offset') || 0;

        if (typeof target !== 'undefined') {
          common.goToPos(target, offset);
        }
      });
  },

  initEnterViewport() {
    const $el = $('.anim');
		if($el.length) {
      common.scroller
        .setup({
          step: '.anim',
          // once: true,
          offset: 0.8
        })
        .onStepEnter((response) => {
          // { element, index, direction }
          const delay = $(response.element).data('delay') * 1000 || 0

          setTimeout(() => {
            $(response.element).addClass('in-view')
          }, delay);
        })
    }
  }
}

module.exports = common;
